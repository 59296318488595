@import '../../variables.scss';
@import '../../mixin.scss';

.connectWalletModal {
  :global {
    .modal-dialog {
      .modal-content {
        .modal-header {
          justify-content: flex-start;
          margin-bottom: 16px;
          h2 {
            font-size: 18px;
            line-height: 24px;
            font-weight: 700;
            color: $DarkBlack;
            text-align: left;
          }
        }
      }
      .modal-body {
        .contentContainer {
          padding-top: 0;
          
          .formContainer {
            max-height: 420px;
            overflow-y: scroll;
            justify-content: flex-start;
          }
        }
      }
    }
  }
  .formWrap {
    .desc {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: $Grey;
      text-align: left;
    }
    .btnGroup {
      width:100%;
      margin-top: 4px;
      button {
        padding: 0 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 12px 0;
        width: 100%;
        height: 60px;
        border-radius: 8px;
        background: $PureWhite;
        &:hover {
          background: $LineGrey;
        }
        >img {
          height: 30px;
        }
        .msg {
          margin-left: 13px;
          font-size: 14px;
          line-height: 24px;
          font-weight: 400;
          color: $DarkBlack;
        }
      }
    }
  }
}

.link{
  text-transform: capitalize;
  text-decoration: none;
  cursor: pointer;
  font-size: small;
  font-weight: bold;
}


.metamaskToast {
  dl, dt, dd {
    margin: 0;
    padding: 0;
  }
  dt {
    display: flex;
    flex-direction: row;
    align-items: center;
    >img {
      width: 24px;
      height: 24px;
    }
    >span {
      margin-left: 12px;
      font-size: 20px;
      font-weight: 500;
      color: $DarkBlack;
    }


  }
  dd {
    margin-top: 9px;
    margin-left: 36px;
    a {
      color: $Green;
      line-height: 1.5;
      font-size: 14px;
    }
  }

}