@import './variables.scss';
@import './useMixin.scss';

@mixin Barlow {
  font-family: 'Barlow', sans-serif;
}
@mixin Montserrat {
  font-family: 'Montserrat', sans-serif;
}

@mixin Roboto {
  font-family: 'Roboto', sans-serif;
}



@mixin rubik {
  font-family: 'Rubik', sans-serif, tahoma,arial,'Hiragino Sans GB','\5b8b\4f53';
}

@mixin jura($letterSpacing:0.17em) {
  font-family: 'Jura', sans-serif, tahoma,arial,'Hiragino Sans GB','\5b8b\4f53';
  font-weight: bold;
  letter-spacing: $letterSpacing;
}

@mixin daysOne {
  font-family: 'Days One', sans-serif, tahoma,arial,'Hiragino Sans GB','\5b8b\4f53';
}



@mixin inner {
  max-width: 1232px;
  padding-left: 16px;
  padding-right: 16px;
}

@mixin flex($dir:row, $justifyContent:center, $wrap: nowrap) {
  display: flex;
  flex-direction: $dir;
  align-items: center;
  justify-content: $justifyContent;
  flex-wrap: $wrap;
}

@mixin no-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@mixin btn-large {
  font-size: 18px;
  line-height: 1;
}
@mixin btn-medium {
  font-size: 14px;
  line-height: 1;
}
@mixin btn-small {
  font-size: 10px;
  line-height: 1;
}
@mixin btn-xs {
  font-size: 8px;
  line-height: 1;
}
@mixin btn-extended {
  padding: 1.5em 2em;
  border-radius: 1.5em/1.3em;
}
@mixin btn-normal {
  padding: 1.5em 2em;
  border-radius: 1.5em/1.3em;
}
@mixin btn-narrow {
  padding: 1em 1.5em;
  border-radius: 1em/1.1em;
}

@mixin primary($fontSize:12px, $radius:1em 1.1em, $padding: 1em 1.5em, $afterPadding:1px) {
  position: relative;
  display: inline-block;
  color: $v2_text;
  text-decoration: none;
  background-color: transparent!important;
  border:0;
  outline: 0;
  margin: 0;
  background-image: linear-gradient(250deg, rgba($v2_brand-b,.4), rgba($v2_brand-c,.4), rgba($v2_brand-d,.4))!important;
  line-height: 1;
  font-size: $fontSize;
  border-top-left-radius:$radius;
  border-top-right-radius: $radius;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  padding: $padding;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(250deg, $v2_bg-4, $v2_bg-4),
                      linear-gradient(250deg, rgba($v2_brand-b,1), rgba($v2_brand-c,1), rgba($v2_brand-d,1))!important;
    background-origin: border-box;
    background-clip: content-box, padding-box;
    padding:$afterPadding;
    z-index: -1;
    border-top-left-radius:$radius;
    border-top-right-radius: $radius;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  &:focus {
    box-shadow: 0 0 34px 0 rgba($v2_brand-b, 0.3), 0 0 2px 0 $v2_brand-b, 
                inset 0 0 3px 0 $v2_brand-b, inset 0 0 23px 0 rgba($v2_brand-b, 0.3)!important;
    &::after {
      border: solid 1px $v2_brand-b!important;
    }
  }
  &:hover {
    color: $v2_text!important;
    background-image: linear-gradient(250deg, rgba($v2_brand-b,.6), rgba($v2_brand-c,.6), rgba($v2_brand-d,.6))!important;
  }
  &:active {
    color: $v2_text!important;
    box-shadow: inset 0 6px 14px 0 $v2_bg!important;
    background-color: transparent!important;
    background-image: linear-gradient(250deg, rgba($v2_brand-b,.4), rgba($v2_brand-c,.4), rgba($v2_brand-d,.4))!important;
    border:none;
  }
  &:disabled {
    opacity: 1!important;
    color: rgba($v2_text, .5)!important;
    cursor: not-allowed;
    &::after {
      opacity: .5;
    }
    &:hover {
      background-image: linear-gradient(250deg, rgba($v2_brand-b,.4), rgba($v2_brand-c,.4), rgba($v2_brand-d,.4))!important;
    }
  }
}

@mixin ghost($fontSize:12px, $radius:1em 1.1em, $padding: 1em 1.5em, $afterPadding:1px) {
  position: relative;
  display: inline-block;
  color: $v2_text;
  text-decoration: none;
  background-color: transparent!important;
  border:0;
  outline: 0;
  margin:0;
  line-height: 1;
  font-size: $fontSize;
  border-top-left-radius:$radius;
  border-top-right-radius: $radius;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  padding: $padding;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(250deg, $v2_bg-4, $v2_bg-4),
                      linear-gradient(250deg, $v2_brand-b, $v2_brand-c, $v2_brand-d)!important;
    background-origin: border-box;
    background-clip: content-box, padding-box;
    padding:$afterPadding;
    z-index: -1;
    border-top-left-radius:$radius;
    border-top-right-radius: $radius;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  &:focus {
    box-shadow: 0 0 34px 0 rgba($v2_brand-b, 0.3), 0 0 2px 0 $v2_brand-b, 
                inset 0 0 3px 0 $v2_brand-b, inset 0 0 23px 0 rgba($v2_brand-b, 0.3)!important;
  }
  &:hover {
    background-image: linear-gradient(to right, rgba($v2_brand-b, 0), rgba($v2_brand-b, 0.3))!important;
  }
  &:active {
    color: $v2_text!important;
    box-shadow: inset 0 6px 14px 0 $v2_bg;
    background-color: transparent!important;
    background-image: linear-gradient(250deg, rgba($v2_brand-b, 0.4), rgba($v2_brand-c, 0.4), rgba($v2_brand-d, 0.4))!important;
    border:none;
  }
  &:disabled {
    opacity: 1!important;
    color: rgba($v2_text, .5)!important;
    cursor: not-allowed;
    &::after {
      opacity: .5;
    }
    &:hover {
      background-image: initial!important;
    }
  }
}


@mixin secondary-A() {
  $bgColor: rgba($v2_brand-i, 0.2);
  $borderColor:$v2_brand-a;
  @include secondary($bgColor, $borderColor);
  
}

@mixin secondary-B() {
  $bgColor: rgba($v2_brand-b, 0.2);
  $borderColor:$v2_brand-b;
  @include secondary($bgColor, $borderColor);
}

@mixin tertiary-A() {
  border: solid 1px rgba($v2_brand-j, 0.15)!important;
  background-color: $v2_brand-n!important;
  background-image: linear-gradient(to bottom, rgba($v2_brand-i, 0.1), rgba($v2_brand-i, 0.1)), 
                    linear-gradient(to bottom, $v2_bg-4, $v2_bg-5)!important;
  color:$v2_text;
  line-height: 1;
  cursor:pointer;
  &:focus {
    @include focus;
  }
  &:hover {
    box-shadow: 0 3px 11px 0 rgba($v2_bg-1, 0.3)!important;
    background-image: linear-gradient(to right, rgba($v2_brand-b, 0) -3%, rgba($v2_brand-b, 0.3)),
                      linear-gradient(to bottom, rgba($v2_brand-i, 0.1), rgba($v2_brand-i, 0.1)), 
                      linear-gradient(to bottom, $v2_bg-4, $v2_bg-5)!important;
  }
  &:active {
    box-shadow: inset 0 6px 14px 0 $v2_bg!important;
    border: solid 1px $v2_bg!important;
    color: $v2_text!important;
  }
  &:disabled {
    opacity: .5!important;
    cursor: not-allowed;
    &:hover {
      box-shadow: initial;
      background-color: transparent;
      background-image: linear-gradient(to bottom, rgba($v2_brand-i, 0.1), rgba($v2_brand-i, 0.1)), 
                        linear-gradient(to bottom, $v2_bg-4, $v2_bg-5);
    }
  }
}


@mixin tertiary-B($opacity:0.15, $bg1:$v2_bg-6, $bg2:$v2_bg-7) {
  background-color: $v2_bg;
  background-image: linear-gradient(to bottom, rgba($v2_brand-i, $opacity), rgba($v2_brand-i, $opacity)), 
                    linear-gradient(to bottom, $bg1, $bg2);
  color:$v2_text;
  line-height: 1;
  cursor: pointer;
  &:focus {
    @include focus;
  }
  &:hover {
    box-shadow: 0 3px 11px 0 rgba($v2_bg-1, 0.3);
    background-image: linear-gradient(to right, rgba($v2_brand-b, 0) -3%, rgba($v2_brand-b, 0.3))
                      linear-gradient(to bottom, rgba($v2_brand-i, $opacity), rgba($v2_brand-i, $opacity)), 
                      linear-gradient(to bottom, $bg1, $bg2);
  }
  &:active {
    box-shadow: inset 0 6px 14px 0 $v2_bg;
    color: $v2_text!important;
  }
  &:disabled {
    opacity: .5!important;
    cursor: not-allowed;
    &:hover {
      box-shadow: initial;
      background-color: transparent;
      background-image: linear-gradient(to bottom, rgba($v2_brand-i, $opacity), rgba($v2_brand-i, $opacity)), 
                        linear-gradient(to bottom, $bg1, $bg2);
    }
  }
}



@mixin divider-1 {
  box-shadow: 0 1px 0 0 rgba($v2_text, 0.03);
  border-bottom: 1px solid rgba($v2_bg, .6);
}

@mixin divider-2 {
  box-shadow: 0 1px 0 0 rgba($v2_text, 0.03);
  border-bottom: 1px solid rgba($v2_bg-1, .7);
}

@mixin card-1-dark($radius:48px, $blur:2px) {
  border-radius: $radius;
  backdrop-filter: blur($blur);
  border: solid 1px rgba($v2_brand-j, 0.15);
  box-shadow: 0 19px 31px -6px rgba(0, 0, 0, 0.15);
  background-image: radial-gradient(circle at 19% 21%, rgba($v2_brand-a, 0.2), rgba($v2_brand-a, 0) 43%), 
            radial-gradient(circle at 58% 38%, rgba($v2_brand-g, 0.27), rgba($v2_brand-g, 0) 75%), 
            linear-gradient(to bottom, rgba($v2_brand-h, 0.91), rgba($v2_brand-h, 0.91)),
            linear-gradient(to bottom, $v2_bg-4, $v2_bg-3);
}

@mixin card-A($radius:48px, $blur:2px, $percent:55%, $circleL: 19%, $circleR: 21%) {
  border-radius: $radius;
  backdrop-filter: blur($blur);
  border: solid 1px rgba($v2_brand-j, 0.15);
  box-shadow: 0 0 0 1px rgba($v2_text, 0.03) inset,
              0 0 0 10px rgba($v2_text, 0.01) inset,
              0 19px 31px -6px rgba(0, 0, 0, 0.15);
  background-image: radial-gradient(circle at $circleL $circleR, rgba($v2_brand-f, 0.2), rgba($v2_brand-f, 0) $percent), 
                    linear-gradient(to bottom, rgba($v2_white, 0), rgba($v2_black, 0.1)), 
                    linear-gradient(to bottom, $v2_bg-4, $v2_bg-3);
}
@mixin card-B($radius:48px, $blur:2px, $percent:55%, $circleL: 19%, $circleR: 21%) {
  border-radius: $radius;
  backdrop-filter: blur($blur);
  border: solid 1px rgba($v2_brand-j, 0.15);
  box-shadow: 0 0 0 1px rgba($v2_text, 0.03) inset,
              0 0 0 10px rgba($v2_text, 0.01) inset,
              0 19px 31px -6px rgba(0, 0, 0, 0.15);
  background-image: radial-gradient(circle at $circleL $circleR, rgba($v2_brand-f, 0.2), rgba($v2_brand-f, 0) $percent), 
                    linear-gradient(to bottom, $v2_bg-3, $v2_bg-4);
}


@mixin input($placeholderOpacity:0.5) {
  color:$v2_text;
  border-radius: 14px;
  border: solid 1px rgba($v2_brand-j, 0.15);
  background-image: linear-gradient(to bottom, rgba($v2_brand-i, 0.06), rgba($v2_brand-i, 0.06)), 
                    linear-gradient(to bottom, $v2_bg, $v2_bg-3);
  line-height: 1;
  &:focus {
    outline: 0;
    box-shadow: 0 0 34px 0 rgba($v2_brand-b, 0.3), 0 0 2px 0 $v2_brand-b, inset 0 0 3px 0 $v2_brand-b, inset 0 0 23px 0 rgba($v2_brand-b, 0.3);
    border: solid 1px $v2_brand-b;
  }
  &::placeholder {
    opacity: $placeholderOpacity;
    color:$v2_text;
  }
}


@mixin card-icon($size:32px, $radius:10px) {
  cursor: pointer;
  color:$v2_text;
  width: $size;
  height: $size;
  @include card-dark($radius, false);
  &:hover {
    box-shadow: 0 3px 11px 0 rgba($v2_bg-1, 0.3);
    background-image: linear-gradient(227deg, rgba($v2_brand-b, 0.3) 96%, rgba($v2_brand-b, 0) 0%);
  }
  &:active {
    box-shadow: inset 0 6px 14px 0 $v2_bg;
  }
}

@mixin progressBar {
  position: relative;
  width:100%;
  border-radius: 4px;
  height: 8px;
  background-color: rgba($PureWhite, 0.2);
  .barInner {
      position: absolute;
      left:0;
      top:0;
      bottom:0;
      border-radius: 6px;
      max-width: 100%;
  }
  .barCircle {
    @include ghost(12px, 6px, 0);
    position: absolute;
    left:0;
    top:-5px;
    width: 22px;
    height: 22px;     
    padding:1px;
    z-index:2;
    margin-left: -5px;
    cursor: pointer;
  }
}

@mixin dot {
  content: '';
  position:absolute;
  width: 8px;
  height: 8px;
  border: 1px solid $v2_error;
  background: rgba($v2_error, 0.7);
  border-radius: 50%;
}

// welcome

@mixin primary-btn($fontSize:18px, $radius:27px, $padding: 1.5em 2em) {
  position: relative;
  display: inline-block;
  color: $v2_brand-o;
  text-shadow: 0 1px 3px rgba($v2_black, 0.08);
  text-decoration: none;
  background-color: transparent;
  border:0;
  outline: 0;
  background-image: linear-gradient(250deg, rgba($v2_brand-b,.4), rgba($v2_brand-c,.4), rgba($v2_brand-d,.4));
  @include daysOne;
  line-height: 1;
  font-size: $fontSize;
  border-radius: $radius;
  padding: $padding;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(250deg, $v2_brand-l, $v2_brand-l),
                      linear-gradient(250deg, rgba($v2_brand-b,1), rgba($v2_brand-c,1), rgba($v2_brand-d,1));
    background-origin: border-box;
    background-clip: content-box, padding-box;
    padding:2px;
    z-index: -1;
    border-radius: $radius;
  }
  &:hover {
    box-shadow: 0 0 43px $v2_brand-m,
                inset 0 0 4px $v2_brand-a;
  }
}


@mixin secondary-btn($fontSize:18px, $radius:27px, $padding: 1.5em 2em) {
  position: relative;
  display: inline-block;
  border-radius:  1.5em / 1.3em;
  color: $v2_brand-o;
  text-shadow: 0 1px 3px rgba($v2_black, 0.08);
  text-decoration: none;
  background-color: transparent;
  border:0;
  outline: 0;
  @include daysOne;
  line-height: 1;
  font-size: $fontSize;
  border-radius: $radius;
  padding: $padding;
  &::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(250deg, $v2_brand-l, $v2_brand-l),
                      linear-gradient(250deg, rgba($v2_brand-b,.6), rgba($v2_brand-c,.6), rgba($v2_brand-d,.6));
    background-origin: border-box;
    background-clip: content-box, padding-box;
    padding:2px;
    z-index: -1;
    border-radius: $radius;
  }
  &:hover {
    box-shadow: 0 0 43px $v2_brand-m,
                inset 0 0 4px $v2_brand-a;
  }
}


@mixin gradient {
  @include card-dark--colorful(50%, false);
}

@mixin circleItem {
    @include flex(column, center);
    .circle {
      position: relative;
      width: 109px;
      height: 109px;
      @include flex(column, center);
      @include gradient;
      background-color: $v2_brand-h;
      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
      }
      .innerCircle {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        background-color: rgba($v2_bg, .8);            
        @include flex(column, center);
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .txtWrap {
      @include flex(column, center);
      margin-top: 16px;
      .num {
        @include daysOne;
        font-size: 28px;
        line-height: 1.1;
        text-align: center;
      }
      .txt {
        @include jura;
        font-size: 16px;
        margin-top: 8px;
        text-align: center;
        white-space: nowrap;
      }
    }
}

@mixin infoItem($dir:column) {
  @include flex($dir, flex-start, wrap);
  @if $dir == 'column' {
    align-items: flex-start;
  } @else {
    align-items: center;
  }
  .circle {
    width: 60px;
    height: 60px;
    @include flex(column, center, wrap);
    @include gradient;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .txt {
    display: inline-block;
    @include rubik;
    font-size: 18px;
    line-height: 1.6;
    max-width: 264px;
    padding-top: 18px;
  }
}

@mixin shape($size:130px, $color:$v2_brand-b, $insetShadow:87px, $rotate:-315deg, $opacity:0.8, $radius: 36%) {
  width: $size;
  height: $size;
  transform: rotate($rotate);
  opacity: $opacity;
  border-radius: $radius;
  box-shadow: 0 0 1px 0 $color, 0 0 3px 0 $color, inset 0 1px $insetShadow 0 $color;
  border: 1.8px solid $color;
}

@mixin card-dark($radius:24px, $backdrop:true) {
  border-radius: $radius;
  background-color: rgba($v2_brand-h, 0.91);
  @if $backdrop {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
  box-shadow: 
      0 0 0 1px rgba($v2_text, 0.03) inset,
      0 0 0 10px rgba($v2_text, 0.01) inset,
      0 19px 31px -6px rgba(0, 0, 0, 0.15);
  background-image: 
      radial-gradient(circle at 19% 21%, rgba($v2_brand-f, 0.1), rgba($v2_brand-f, 0) 55%), 
      radial-gradient(circle at 58% 38%, rgba($v2_brand-g, 0.15), rgba($v2_brand-g, 0) 95%), 
      linear-gradient(to bottom, rgba($v2_text, 0.01), rgba($v2_text, 0.03));
}

 
@mixin card-dark--hover($radius:24px, $backdrop:true) {
  @include card-dark($radius, $backdrop);
  box-shadow: 0 0 2px 0 $v2_brand-b,
              0 0 34px 0 rgba($v2_brand-b, 0.23),
              inset 0 0 3px 0 $v2_brand-b,
              inset 0 0 23px 0 rgba($v2_brand-b, 0.3);
}

@mixin card-dark--colorful($radius:24px, $backdrop:true){
  @include card-dark($radius, $backdrop);
  background-image: 
      radial-gradient(circle at 19% 21%, rgba($v2_brand-f, 0.2), rgba($v2_brand-f, 0) 55%), 
      radial-gradient(circle at 58% 38%, rgba($v2_brand-g, 0.3), rgba($v2_brand-g, 0) 95%); 
}


// 

@mixin greenBtn($padding: 12px 22px, $fontWeight: 700, $fontSize:14px) {
  @include Barlow;
  font-size: $fontSize;
  color: $PureWhite;
  font-weight: $fontWeight;
  line-height: 1.2;
  padding: $padding;
  background: $Green;
  border-radius: 20px;
  text-decoration: none;
  &:hover {
    background: linear-gradient(rgba($PureWhite, .2), rgba($PureWhite, .2)), linear-gradient($Green, $Green);
    color: $PureWhite;
  }
  &:active {
    background: linear-gradient(rgba($PureBlack, .2), rgba($PureBlack, .2)), linear-gradient($Green, $Green);
    color: $PureWhite;
  }
  &:disabled {
    background: $LGrey;
    color: $PureWhite;
  }
}

@mixin blueBtn($padding: 12px 22px, $fontWeight: 700, $fontSize:14px) {
  @include greenBtn($padding, $fontSize);
  background: $Blue;
  &:hover {
    background: linear-gradient(rgba($PureWhite, .2), rgba($PureWhite, .2)), linear-gradient($Blue, $Blue);
    color: $PureWhite;
  }
  &:active {
    background: linear-gradient(rgba($PureBlack, .2), rgba($PureBlack, .2)), linear-gradient($Blue, $Blue);
    color: $PureWhite;
  }
  &:disabled {
    background: $LGrey;
    color: $PureWhite;
  }
}