@import '../../variables.scss';
@import '../../mixin.scss';

.headerWrap {
    background-color: $BgBlack;
    &.headerWrapNoBg {
        background-color: transparent;
    }
    .header {
        color: $PureWhite;
        padding: 20px 0;
        .headerContainer {
            @include inner;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .logoWrap {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 100px;
            }
            .navWrap {
                justify-content: space-between;
            }
            .menuItemContainer {
                padding: 10px 20px;
                .menuItem {
                    text-transform: capitalize;
                    text-decoration: none;
                    color: $PureWhite;
                    text-align: center;
                    font-size: 16px;
                    margin: 5px 15px;
                    font-weight: 400;
                    .txt {
                        padding: 5px 0;
                    }
                    &.menuItemHot {
                        margin-right: 55px;
                        .txt {
                            position: relative;
                            &::after {
                                content: '';
                                position: absolute;
                                left:calc(100% + 5px);
                                top:50%;
                                width: 40px;
                                height: 18px;
                                background: url('../../images/freshBank/hot.png') no-repeat 0 0;
                                background-size: 100% 100%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                    
                    &.active {
                        .txt {
                            border-bottom: 2px solid $Green;
                            font-weight: 700;
                        }
                    }
                    &:hover {
                        opacity: 1.0;
                    }
                }
            }
    
            .buttonsContainer {
                align-items: flex-end;
                .walletBtn {
                    margin-left: 6px;
                    margin-right: 6px;
                }
                .switchNetworkWrap {
                    margin-left: 6px;
                    margin-right: 6px;
                    .networkBtn {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        >img {
                            margin-right: 5px;
                        }
                        >span {
                            font-size: 14px;
                            line-height: 16.8px;
                            font-weight: 500;
                        }
                    }
                    .rightNetwork {
                        >span {
                            color: $Green;
                        }
                    }
                    .wrongNetwork {
                        >span {
                            color: $Red;
                        }
                    }
                }
                .switchLangWrap {
                    margin-left: 6px;
                    margin-right: 6px;
                }
            }
        }
    }
    
    .tipsContainer {
        background-color: $BgBlack;
        .errorInfo {
            margin:0;
            padding:0;
            min-height: 42px;
            display:flex;
            align-items: center;
            justify-content: center;
            background: rgba($Red, 1);
            color: $PureWhite;
            font-size: 12px;
            line-height: 1.5;
            border-radius: 10px;
            >svg {
                vertical-align: center;
                color: $PureWhite;
                font-size: 16px;
            }
            >span {
                margin-left: 4px;
            }
        }
    }

    .connectedAddress {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-width: 1px;
        padding-top: 6px;
        padding-bottom: 6px;
        height: 38px;
        img {
            height: 20px;
            width: 20px;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 16.8px;
            margin-left: 6px;
        }

    }

    .menuLogo + .walletBtn {
        display: none;
    }
}




@media (max-width: 991.98px) { 
    .headerWrap {
        .header {
            padding: 22px 0;
            .headerContainer {
                padding-left: 0;
                padding-right: 0;
                justify-content: space-between;
                .menuItemContainer {
                    padding: 0 20px;
                }
                .menuLogo {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .logoWrap {
                        margin-right: 0;
                    }
                    >button {
                        padding: 14px;
                    }
                }

                .menuItemContainer {
                    align-items: center;
                    .menuItem {
                        margin:0;
                        padding: 16px 0;
                        display: inline-flex;
                        &:first-child {
                            margin-top: 16px;
                        }
                        &.menuItemHot {
                            margin-right: 15px;
                        }
                        &.active {
                            .txt {
                                padding-left: 15px;
                                padding-right: 15px;
                            }
                        }
                    }
                }
                .buttonsContainer {
                    align-items: center;
                    .switchNetworkWrap {
                        margin: 0;
                        margin: 16px 0;
                        :global {
                            .dropdown {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                .dropdown-menu {
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                    .switchLangWrap {
                        margin: 0;
                        margin: 16px 0;
                        :global {
                            .dropdown {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                .dropdown-menu {
                                    margin-top: 10px;
                                    top: initial;
                                    a {
                                        text-align: center;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .menuLogo + .walletBtn {
            display: block;
            margin-right: 12px;
        }
        .switchNetworkWrap + .walletBtn {
            display: none;
        }
    }
}







