@import '../../variables.scss';
@import '../../mixin.scss';

.btnGroup {
	width: 100%;
	margin-top: 15px;

	&.disabled {
		filter: grayscale(70%);
		pointer-events: none;
	}

	label {
		line-height: 14.06px;
		font-size: 12px;
		font-weight: 400;
		border: 1px solid $LightenGrey;
		border-radius: 4px;
		padding: 5px 12px;
		margin-right: 16px;
		color: $DarkenGrey;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			border: 1px solid $LightGrey;
			color: $DarkBlack;
			background: transparent;
		}

		&:active {
			background: transparent;
		}
	}

	input:checked+label {
		border: 1px solid $Green;
		background: rgba($Green, .1);
		color: $Green;
	}

	&.cleanUp {
		input:checked+label {
			border: 1px solid $LightenGrey;
			background: transparent;
			color: $DarkenGrey;
		}
	}
}

.sliderBlock {
	background: #EFEFF2;
	border-radius: 12px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px;
	width: calc(80% + 4px);
	margin-right: 1em;
}