@import '../../variables.scss';
@import '../../mixin.scss';

.myWalletModal {
  :global {
    .modal-dialog {
      .modal-content {
        min-height: 350px;
        .modal-header {
          justify-content: flex-start;
          h2 {
            font-size: 18px;
            line-height: 21.6px;
            font-weight: 700;
            color: $DarkBlack;
            text-align: left;
          }
        }
        .modal-body {
          .contentContainer {
            padding-top: 0;
            .formContainer {
              min-height: initial;
            }
          }
        }
        .modal-footer {
          button {
            width: 100%;
          }
        }
      }
    }
  }
  .copySuccess {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background: $LightenGrey2;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba($Grey, .1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    >img {
      height: 20px;
      margin-right: 4px;
    }
    >span {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      color: $DarkBlack;
    }
  }
  .formWrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .walletAddress,
    .network {
      .title {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 16.8px;
        font-weight: 500;
        color: $Grey;
      }
      .content {
        width: 100%;
        padding: 10px 12px;
        height: 44px;
        background: $LightenGrey2;
        border-radius: 8px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        color: $DarkBlack;
        text-align: left;
      }
    }
    .walletAddress {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .content {
        display: flex;
        flex-direction: row;
        span {
          flex: 1 0 26px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        img {
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
    .network {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 20px;
      img {
        margin-right: 8px;
      }
    }
    .viewBlockChain {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top:23px;
      cursor: pointer;
      transition: all .2s ease;
      text-decoration: none;
      >span {
        font-weight: 500;
        font-size: 14px;
        line-height: 16.8px;
        color: $Green;
      }
      >img {
        margin-left: 4px;
      }
      &:hover {
        transform: translateY(-2px);
      }
    }
  }
}